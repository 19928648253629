<template>
	<div>
		<v-row class="mt-1 ml-2 mb-3">
			<v-col>
				<h2 class="primary--text">Bank Advice</h2>
			</v-col>
			<v-col class="text-right">
				<!-- export advice -->
				<v-menu transition="scale-transition" origin="center center" offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="primary" v-bind="attrs" v-on="on" @click="exportBankAdvice">
							Export Advice
							<v-icon class="pl-1" size="24"> mdi-export </v-icon>
						</v-btn>
					</template>
				</v-menu>
			</v-col>
		</v-row>

		<!-- Spinner -->
		<div v-if="isloading">
			<spinner></spinner>
		</div>
		<!-- search -->
		<v-row>
			<v-col cols="12" sm="3">
				<v-text-field @keyup.enter="handlefilter" solo prepend-inner-icon="mdi-magnify"
					label="Name" dense clearable flat @click:clear="resetData" color="primary"
					background-color="white lighten-4" v-model="filterForm.name"></v-text-field>
			</v-col>

			<!-- filter advice -->
			<v-col cols="12" sm="3" md="3">
				<v-select solo dense flat label="Department" :items="departments" item-text="name" item-value="abbreviation"
					v-model="filterForm.department"></v-select>
			</v-col>

			<v-col cols="12" sm="3" md="3">
				<v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="filterForm.date"
					transition="scale-transition" offset-y max-width="290px" min-width="auto">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field dense solo flat background-color="white" placeholder="yyyy-mm"
							v-model="filterForm.date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
							v-on="on"></v-text-field>
					</template>
					<v-date-picker v-model="filterForm.date" type="month" no-title scrollable>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="menu = false">
							Cancel
						</v-btn>
						<v-btn text color="primary" @click="$refs.menu.save(filterForm.date)">
							OK
						</v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>

			<v-col cols="12" md="3" sm="3" class="d-flex justify-content-between">
				<v-btn color="primary" @click.prevent="handlefilter">filter</v-btn>
				<v-btn color="#eff" class="primary--text" @click.prevent="resetData">Clear</v-btn>
			</v-col>
		</v-row>
		<v-data-table fixed-header height="80vh" :headers="headers" :items="bankAdviceReport" disable-sort
			class="table-rounded elevation-1" :page.sync="page" @page-count="pageCount = $event"
			:items-per-page="itemsPerPage" hide-default-footer>
			<template v-slot:[`item.no`]="{ index }">
				{{ index + 1 + pageNumbering }}
			</template>
			<template v-slot:[`item.bankName`]="{ item }">
				{{ item.employee?.employeeBankDetails?.bankName }}
			</template>
			<template v-slot:[`item.accountNumber`]="{ item }">
				{{ item.employee?.employeeBankDetails?.accountNumber }}
			</template>
			<template v-slot:[`item.sortCode`]="{ item }">
				{{ item.employee?.employeeBankDetails?.sortCode }}
			</template>
			<template v-slot:[`item.depositorAccountNumber`]="{ item }">
				{{
					item.employeeId?.slice(0, 2) === "TRN"
					? "0090108077491"
					: "0090228077491"
				}}
			</template>
			<template v-slot:[`item.payerName`]="{ item }">
				{{
					item.employeeId?.slice(0, 2) === "TRN"
					? "AmaliTech/GIZ Cedi Account"
					: "AmaliTech Cedi Account"
				}}
			</template>
			<template v-slot:[`item.fullName`]="{ item }">
				{{ item.employee.displayName }}
			</template>
			<template v-slot:[`item.instrumentType`]="{}">CR</template>
			<template v-slot:[`item.paymentDate`]="{ item }">{{
				item.date | yearMonth
			}}</template>
			<template v-slot:[`item.transDetail`]="{ item }">{{ item.date | yearMonth }} Salary</template>
			<template v-slot:[`item.netSalary`]="{ item }">
				{{ formatFigure(item.netSalary) }}
			</template>

			<template v-slot:[`body.append`]>
				<tr class="primary--text footer">
					<th id="subtitle" class="subtitle-2">TOTALS</th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2 table-bordered">
						{{ formatFigure(setBankAdviceTotals?.netSalary) }}
					</th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
				</tr>
			</template>
		</v-data-table>
		<div class="text-center pt-2">
			<v-pagination v-model="page" :length="totalPages" @input="paginatePage()" circle></v-pagination>
			<template> </template>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import {EXPORT_BANK_ADVICE_EXCEL} from "../../services/graphql/queries/payrollTierQueries";
import helpers from "../../services/helpers";
export default {
	mixins: [helpers],
	data: () => ({
		isloading: false,
		itemsPerPage: 20,
		dialog: false,
		dialogDelete: false,
		menu: false,
		departments: [
			{ name: "All Departments", abbreviation: "" },
			{ name: "Service Center", abbreviation: "SC" },
			{ name: "Training Center", abbreviation: "TRN" },
			{ name: "National Service Personnel", abbreviation: "NSP" },
			{ name: "Operations", abbreviation: "OP" },
		],
		filterForm: new Form({
			name: "",
			department: "",
			date: "",
		}),

		headers: [
			{
				text: "NO.",
				align: "start",
				sortable: false,
				value: "no",
			},
			{ text: "PAYORBANKROUTNO", value: "bankName" },
			{ text: "TRUNCATINGRTNO", value: "sortCode" },
			{ text: "ACCOUNTNO", value: "accountNumber" },
			{ text: "DEPOSITORACCT", value: "depositorAccountNumber" },
			{ text: "PAYERNAME", value: "payerName" },
			{ text: "PAYEENAME", value: "fullName" },
			{ text: "INSTRUMENTTYPE", value: "instrumentType", align: "center" },
			{ text: "AMOUNT", value: "netSalary", align: "center" },
			{ text: "PRESENTMENTTYPE", value: "paymentDate" },
			{ text: "TRANSDETAIL", value: "transDetail" },
		],
		header: [
			"PAYORBANKROUTNO",
			"TRUNCATINGRTNO",
			"ACCOUNTNO",
			"DEPOSITORACCT",
			"PAYERNAME",
			"PAYEENAME",
			"INSTRUMENTTYPE",
			"AMOUNT",
			"PRESENTMENTDATE",
			"TRANSDETAIL",
		],
	}),
	filters: {
		moment: function (date) {
			return moment(date).format("MMM YYYY");
		},
	},
	computed: {
		bankAdviceReport() {
			return this.$store.getters["report/allPayrollReport"];
		},
		setBankAdviceTotals() {
			return this.$store.getters["report/getMasterPayrollTotals"];
		},
		pageNumbering() {
			if (this.page === 0) {
				return 0;
			} else {
				return this.page * 20 - 20;
			}
		},
		totalPages() {
			return this.$store.getters["report/totalPages"];
		},
		page: {
			get() {
				return this.$store.getters["report/currentPage"];
			},
			set(val) {
				return this.$store.commit("report/SET_CURRENT_PAGE", val);
			},
		},
		data() {
			let today = new Date();
			let date =
				today.getFullYear() +
				"-" +
				("0" + (today.getMonth() + 1)).slice(-2);
			return {
				yearMonth: date,
			};
		},
	},
	async created() {
		this.isloading = true;
		this.filterForm.date = this.data.yearMonth;
		await this.initialize();
		this.isloading = false;
	},
	methods: {
		async initialize() {
			await this.$store.dispatch("report/fetchPayrollReport", {
				data: this.filterForm.date,
				page: this.page || 1,
			});
			await this.$store.dispatch("report/fetchMasterPayrollTotals", {
				data: {
					yearMonth: this.filterForm.date,
					startWith: this.filterForm.department,
				},
			});
		},

		async resetData() {
			this.isloading = true;
			this.filterForm.reset();
			this.filterForm.date = this.data.yearMonth;
			await this.initialize();
			this.isloading = false;
		},

		async paginatePage() {
			this.isloading = true;
			this.scrollToTop();
			await this.$store.dispatch("report/fetchPayrollReport", {
				page: this.page,
				data: this.filterForm.date,
			});
			this.isloading = false;
		},
		scrollToTop() {
			window.scroll({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		},
		async handlefilter() {
			this.isloading = true;
			const data = {
				yearMonth: `${this.filterForm.date}`,
			};
			await this.$store.dispatch("report/filterPayrollReport", {
				page: 1,
				name: this.filterForm.name || "",
				startWith: this.filterForm.department,
				yearMonth: `${data.yearMonth}`,
			});
			this.isloading = false;
		},
		getDepartment(data) {
			let departPrefix = [];
			departPrefix = data.split("-");
			if (departPrefix.length > 0) {
				return departPrefix[0];
			} else {
				return "";
			}
		},
		getDepartmentName(department) {
			switch (department) {
				case "SC":
					return "service_center_";
				case "TRN":
					return "training_center_";
				case "NSP":
					return "national_service_personnel_";
				case "OP":
					return "operations_";
				default:
					return "";
			}
		},

		async exportBankAdvice() {
			this.isloading = true;
			const data = {
				name: this.filterForm.name || "",
				startWith: this.filterForm.department,
				yearMonth: `${this.filterForm.date}`,
			};

			const date = moment(data.yearMonth).format("MMM YYYY");

			this.$apollo.query({
				query: EXPORT_BANK_ADVICE_EXCEL,
				variables: {
					startWith: data.startWith,
					responseType: "blob",
					headers: { Accept: "multipart/form-data" },
				}
			})
				.then((response) => {
					const title = `BANK ADVICE FOR - ${date}.xls`;
					const fileLink = response.data.exportBankAdviseExcel;
					const a = Object.assign(document.createElement("a"), { href: fileLink }, { download: title });
					a.click();
					a.remove();
					this.isloading = false;
				})
				.catch(() => {
					this.isloading = false;
				});
		},
	},
};
</script>

<style>
.v-toolbar__title {
	font-weight: bolder;
	font-size: 1.2rem;
}
</style>
